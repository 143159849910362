import React from 'react'

const History = () => {
    return (
        <div>
            History module
        </div>
    )
}

export default History;