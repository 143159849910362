import React, { useState } from "react";
import HorizontalBarChart from "../../components/charts/HorizontalBarChart";
import { data } from "../../api/chart-data-test";
import SimpleButton from "../../components/buttons";

const Dashboard = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleButtonClick = (e) => {
        setIsModalOpen(!isModalOpen)
    }

  return (
    <div
      className="d-flex mt-1 flex-column mx-auto align-items-center bg-white"
      style={{ maxWidth: 1280 }}
    >
      <HorizontalBarChart data={data} />
      <div className="d-flex justify-content-end w-100 me-5">
        <SimpleButton
            onClick={handleButtonClick}
          text={`Nova medição `}
          className={"btn-primary"}
          icon={<i class="fa-regular fa-plus ps-1"></i>}
        />
      </div>
    </div>
  );
};

export default Dashboard;
