import React from 'react';
import brandIcon from '../../assets/brand.svg'

const Home = () => {
    return(
        <div className='d-flex wrap justify-content-center align-items-center h-100' style={{flexDirection: 'column'}}>
            <img src={brandIcon} alt="brand-icon" width={300} height={80}/>
            <h1 style={{fontSize: 60, fontWeight:'bold'}} className='mt-5'>COMING SOON...</h1>
        </div>
    )
}


export default Home;