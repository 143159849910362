import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./routes/routes";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";

function App() {
  const routesComponents = routes.map(({path, component}, index) => <Route key={index} path={path} element={component}/>)

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {routesComponents}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
