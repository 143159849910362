import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
} from "recharts";
import './style.css'

const HorizontalBarChart = (props) => {
  const { data } = props;
  return (
    <div className="horizontal-bar-chart d-flex justify-content-center align-items-center ">
      <BarChart
        width={850}
        height={350}
        data={data}
       
        margin={{
          top: 25,
          right: 20,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="2 2" />
        <XAxis dataKey='date' />
        <YAxis />
        <Tooltip />
        <Bar dataKey='value' fill="#000"  label={{ position: "top" }}> 
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} />
        ))}
        </Bar>
      </BarChart>
    </div>
  );
};

export default HorizontalBarChart;
