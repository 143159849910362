export const data = [
    {
      date: '01/02/2023',
      tod: 'Diner',
      value: 120,
      fill: "#8790ed"
    },
    {
      date: '02/02/2023',
      value: 100,
      tod: 'Breakfast',
      fill: "#a7b299"
    },
    {
      date: '03/02/2023',
      value: 115,
      tod: 'Lunch',
      fill: "#02e3b5"
    },
    {
      date: '04/02/2023',
      value: 98,
      tod: 'Diner',
      fill: "#8790ed"
    },
    {
      date: '05/02/2023',
      value: 86,
      tod: 'Breakfast',
      fill: "#a7b299"
    },
  ];