import Dashboard from '../views/dashboard-module'
import History from '../views/history-module'
import Home from '../views/home-page'

const routes = [
    {
        name: 'Home',
        path: '/',
        component: <Home />,
        isMenuLink: false
    },
    {
        name: 'Dashboard',
        path: '/Dashboard',
        component: <Dashboard />,
        isMenuLink: true
    },
    {
        name: 'History',
        path: '/history',
        component: <History/>,
        isMenuLink: true
    },
    {
        name: 'Signin',
        path: '/signin',
        component: null,
        isMenuLink: false
    }

]

export default routes;